export default {
  header_title: 'Tools',
  content: [
    {
      id: 'goal_setter',
      icon: '$vuetify.icons.starling_goal_setter',
      title: 'goal_setter.title.key',
      subtitle: 'goal_setter.description.key',
      route: {
        name: 'goals-list',
      },
    },
    {
      id: 'thought_balancer',
      icon: '$vuetify.icons.starling_thought_balancer',
      title: 'thought_balancer.title.key',
      subtitle: 'thought_balancer.description.key',
      route: {
        name: 'thoughts-list',
      },
    },
    {
      id: 'mood_tracker',
      icon: '$vuetify.icons.starling_mood_tracker',
      title: 'mood_tracker.title.key',
      subtitle: 'mood_tracker.description.key',
      route: {
        name: 'moods-list',
      },
    },
    {
      id: 'workbook',
      icon: '$vuetify.icons.starling_workbook',
      title: 'workbook.title.key',
      subtitle: 'workbook.description.key',
      route: {
        name: 'plans-list',
      },
    },
  ],
  mood_tracker: {
    moods: [
      'Worried',
      'Sad',
      'Anxious',
      'Stressed',
      'Guilt',
      'Angry',
      'Relaxed',
      'Confident',
      'Happy',
    ],
  },
};
